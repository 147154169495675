<template>
  <div
    class="ellipse absolute"
    :class="{'center': center}"
    :style="blockStyle"
  ></div>
</template>

<script setup>
import { computed } from 'vue';
const props = defineProps({
  size: String | Number,
  opacity: Boolean,
  center: Boolean
})

const blockStyle = computed(() => ({
  fontSize: props.size + 'px',
  opacity: props.opacity ? '1' : '0.6'
}));
</script>

<style lang="scss" scoped>
.ellipse {
  background: $green-400;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  top: -20px;
  left: -20px;
  z-index: 0;
  filter: blur(7px);
  &.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
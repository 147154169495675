<template>
  <section ref="about">
    <div class="container flex flex-col b-about">
      <div class="b-about__how-we-work flex items-end justify-between">
        <div
          class="b-about__text relative"
          v-motion
          :initial="{opacity: 0, y: -100}" 
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 800 }}"
        >
          <Ellipse size="34"></Ellipse>
          <TextBlock
            title="How we work"
            titleSize="headline7"
            text="Rillius provides marketing services worldwide with a passion for driving business growth and a deep understanding of the ever-changing marketing landscape, we are committed to helping businesses succeed in the digital age. Rillius believes in the power of strategic marketing to transform businesses."
            textSize="headline10"
            :gap="windowWdt < 700 ? 20 : 18"
            bg="dark"
          ></TextBlock>
        </div> 
        <ImageBlock
          v-motion
          :initial="{opacity: 0}" 
          :visibleOnce="{opacity: 1, transition: { duration: 800 }}"
          :width="windowWdt < 1200 ? '43%' : ''"
          img="shape2.png"
          :margin="windowWdt < 1200 ? '-10px -60px 0 0' : '-10px -80px 0 0'"
        ></ImageBlock>
      </div>

      <div class="b-about__our-values flex">
        <div class="relative">
          <Element
            v-motion
            :initial="{opacity: 0, y: -50}" 
            :visibleOnce="{opacity: 1, y: 0, transition: { duration: 400, delay: 400 }}"
          ></Element>
          <p
            class="headline3"
            v-motion
            :initial="{opacity: 0, y: -100}"
            :visibleOnce="{opacity: 1, y: 0, transition: { delay: 300, duration: 800 }}"
          >Your goals become our goals, your challenges our challenges </p>
        </div>

        <div class="right flex flex-col">
          <ParallaxImage
            :index="1"
            img="animated1.jpeg"
            width="293"
            height="183"
            v-motion
            :initial="{opacity: 0}" 
            :visibleOnce="{opacity: 1, transition: { duration: 900 }}"
          ></ParallaxImage>
          <div class="flex flex-col gap18">
            <TextBlock
              title="We understand that your success is not just a matter of business, but a reflection of our dedication, expertise, and integrity."
              text="Through collaboration and open communication, we tailor our approach to best serve you, providing personalized solutions that drive results."
              titleSize="subtitle2"
              textSize="text4"
              gap="18"
              v-motion
              :initial="{opacity: 0, y: -20}" 
              :visibleOnce="{opacity: 1, y: 0, transition: { duration: 900, delay: 300 }}"
            ></TextBlock>
            <RouterLink to="/mission-vision" class="no-underline">
              <Button
                wide
                @click="clickValues()"
                v-motion
                :initial="{opacity: 0}" 
                :visibleOnce="{opacity: 1, transition: { duration: 800, delay: 400 }}"
              >Our Values</Button>
            </RouterLink>
          </div>
        </div>
      </div>
      <div class="b-about__why-choose-us flex">
        <ImageBlock
          img="shape3.png"
          :width="windowWdt < 1200 ? windowWdt < 700 ? 'calc(100% - 40px)' : '50%' : '618px'"
          :height="windowWdt < 1200 ? 'auto' : '618px'"
          :margin="windowWdt < 1200 ? windowWdt < 900 ? '-40px auto 0' : '-320px 0 0 -3px' : '-380px 0 0 -3px'"
          v-motion
          :initial="{opacity: 0}" 
          :visibleOnce="{opacity: 1, transition: { duration: 800, delay: 300 }}"
        ></ImageBlock>

        <div class="flex flex-col relative z2">
          <Ellipse size="40"></Ellipse>
          <TextBlock
            title="Why choose us?"
            titleSize="headline7 dark"
            text="Rillius believes in the power of data to drive informed marketing decisions. We utilizes advanced analytics tools and techniques to gather valuable insights about your target market, consumer behavior, and campaign performance. These insights allow us to make data-driven adjustments, optimize your marketing strategies, and achieve optimal results."
            textSize="text4"
            gap="18"
            v-motion
            :initial="{opacity: 0, y: -20}" 
            :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 300 }}"
          ></TextBlock>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import TextBlock from "../additional/TextBlock.vue";
import Ellipse from "../additional/Ellipse.vue";
import ImageBlock from "../additional/ImageBlock.vue";
import ParallaxImage from "../additional/ParallaxImage.vue";
import Button from "../common/Button.vue";
import Element from "@/components/additional/Element.vue";
import { useNavigationStore } from '@/store/navigation';
import getWindowWidth from '@/utils/getWindowWidth';

const windowWdt = getWindowWidth();
const navigationStore = useNavigationStore();

const clickValues = () => {
  navigationStore.setBlockIndex('values');
  navigationStore.setBlockClicked(true);
}
</script>

<style lang="scss">
.b-about {
  gap: 116px;
  overflow: visible !important;
  
  &__text {
    background: $black;
    padding: 133px 85px;
    @include set-width(610px);
    p {
      opacity: 0.92;
    }
    .ellipse {
      top: 127px;
      left: 64px;
    }
  }

  &__how-we-work {
    margin-top: -100px;
    @include breakpoint(small) {
      margin-top: -67px;
    }
  }

  &__our-values {
    gap: 265px;
    .headline3 {
      @include set-width(510px);
    }

    .right {
      @include set-width(410px);
      gap: 28px;
      .parallax {
        margin-top: 8px;
      }
    }
  }

  &__why-choose-us {
    padding-top: 83px;
    gap: 157px;
  }
}

@media (max-width: 1200px) {
  .b-about {
    &__text {
      max-width: 500px;
      min-width: 500px;
    }
    &__our-values, &__why-choose-us {
      gap: 20px;
      justify-content: space-between;
    }
  }
}

@media (max-width: 900px) {
  .b-about {
    gap: 40px;
    &__text {
      max-width: 610px;
    }
    &__how-we-work {
      flex-direction: column;
      align-items: flex-start !important;

      .img-wrap {
        margin: 20px -40px 0 auto !important;
        min-width: 400px !important;
      }
    }
    &__our-values {
      flex-direction: column;
      gap: 40px;
      padding-top: 30px;
      .headline3, .right {
        max-width: none;
      }
      .right {
        gap: 20px;
      }
    }
    &__why-choose-us {
      padding-top: 0;
      flex-direction: column-reverse;

      .img-wrap {
        max-width: 380px;
        min-width: 0 !important;
      }
    }
  }
}

@media (max-width: 700px) {
  .b-about {
    &__text {
      min-width: 0;
      padding: 30px 30px 40px;

      .ellipse {
        top: 25px;
        left: 15px;
      }
    }
    &__how-we-work {
      .img-wrap {
        min-width: 300px !important;
        margin: 20px -20px 0 auto !important;
      }
    }
  }
}
</style>
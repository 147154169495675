<template>
  <div class="modal flex justify-center items-center">
    <template v-if="!isSent">
      <div class="cross absolute">
        <svg @click="$emit('close')" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
          <path d="M0.458055 20.2324C-0.140783 20.8312 -0.169299 21.9004 0.472313 22.5277C1.09967 23.155 2.16902 23.1408 2.76786 22.542L11.4938 13.8025L20.2339 22.542C20.847 23.155 21.9021 23.155 22.5295 22.5277C23.1426 21.8862 23.1568 20.8454 22.5295 20.2324L13.8036 11.4929L22.5295 2.76764C23.1568 2.15459 23.1568 1.09958 22.5295 0.472276C21.8879 -0.140772 20.847 -0.155029 20.2339 0.458019L11.4938 9.19751L2.76786 0.458019C2.16902 -0.140772 1.08541 -0.169285 0.472313 0.472276C-0.155041 1.09958 -0.140783 2.16885 0.458055 2.76764L9.19823 11.4929L0.458055 20.2324Z" fill="white" fill-opacity="0.5"/>
        </svg>
      </div>
      
      <div class="modal__content">
        <div class="mtop white">
          <p class="subtitle4">Don’t be shy</p>
          <p class="headline4">Say Hi!</p>
        </div>

        <Form @response="getResponse"></Form>
      </div>
    </template>
    
    <ResponseModal v-else :isSuccess="isSuccess" @close="closeResponseModal"></ResponseModal>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Form from "./Form.vue";
import ResponseModal from "./ResponseModal.vue";

const emit = defineEmits(['close']);

const isSuccess = ref(false);
const isSent = ref(false);

const getResponse = (val) => {
  isSent.value = true;
  isSuccess.value = val;
}

const closeResponseModal = (val) => {
  console.log(val);
  if(val === 'hide') {
    emit('close');
  } else {
    isSent.value = false;
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 99999;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("@/assets/img/modal-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  &__content {
    @include set-width(662px);
  }
  .absolute {
    right: 40px;
    top: 40px;
    cursor: pointer;
    svg path {
      transition: all .3s ease;
    }
    &:hover svg path {
      fill-opacity: 1;
    }
  }
  .mtop {
    text-align: center;
    .subtitle4 {
      opacity: 0.5;
      margin-bottom: 2px;
    }
  }
}

@media (max-width: 700px) {
  .modal {
    &__content {
      max-width: calc(100% - 40px);
      height: calc(100% - 60px);
      margin-top: 80px;
    }
  }
}
</style>
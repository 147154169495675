<template>
  <div class="partners-parallax relative flex justify-center items-center">
    <ParallaxImage
      :index="2"
      :img="windowWdt > 700 ? 'green2.jpg' : 'green2_mob.jpg'"
      width="full"
      height="500"
      v-motion
      :initial="{opacity: 0}" 
      :visibleOnce="{opacity: 1, transition: { duration: 700, delay: 200 }}"
    ></ParallaxImage>

    <div 
      class="partners-parallax__box absolute flex items-center justify-center"
      v-motion
      :initial="{opacity: 0, scale: 0.8}" 
      :visibleOnce="{opacity: 1, scale: 1, transition: { duration: 700, delay: 400 }}"
    >
      <p 
        class="text4"
        v-motion
        :initial="{opacity: 0}" 
        :visibleOnce="{opacity: 1, transition: { duration: 700, delay: 500 }}"
      >Rillius is a globally operating digital marketing agency, holding registered trademarks in Kazakhstan, the United Kingdom and Tanzania.</p>
    </div>
  </div>
</template>

<script setup>
import ParallaxImage from "@/components/additional/ParallaxImage.vue";
import getWindowWidth from '@/utils/getWindowWidth';

const windowWdt = getWindowWidth();
</script>

<style lang="scss" scoped>
.partners-parallax {
  &__box {
    @include set-width(716px);
    padding: 85px 102px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.34);
    box-shadow: 0px 4px 34px 8px rgba(0, 0, 0, 0.02);
    backdrop-filter: blur(11px);
    color: $white;
  }
}

@media (max-width: 900px) {
  .partners-parallax {
    &__box {
      max-width: calc(100% - 40px);
      padding: 80px 35px;
    }
  }
}

@media (max-width: 700px) {
  .partners-parallax {
    .parallax {
      height: 844px !important;
    }
  }
}
</style>
<template>
  <nav
    class="nav-content flex items-center justify-between"
    :class="{'in-foot': place === 'footer'}"
  >
    <template v-for="(i, id) in navs">
      <RouterLink
        v-if="i.href !== ''"
        :to="i.href"
        :key="id"
        class="nav-item no-underline"
        :class="place === 'footer' ? 'subtitle3' : 'text2'"
      >{{ i.label }}</RouterLink>
      <p
        v-else
        class="nav-item"
        :class="place === 'footer' ? 'subtitle3' : 'text2'"
        @click="handleLinkClick(i)"
      >{{ i.label }}</p>
    </template>
  </nav>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router';
import { useNavigationStore } from '@/store/navigation';
import { NAVS } from "@/constant/nav";

const props = defineProps({
  place: {
    type: String,
    default: "head"
  }
});
const emit = defineEmits(['toggle']);

const router = useRouter();
const navigationStore = useNavigationStore();

const navs = ref(NAVS);

const handleLinkClick = (item) => {
  document.body.classList.remove('no-scroll');
  emit('toggle');
  navigationStore.setBlockIndex(item.ref);
  navigationStore.setBlockClicked(true);
  if(router.currentRoute.value.name !== 'home') router.push('/');
}
</script>

<style lang="scss" scoped>
.nav-content {
  gap: 32px;

  @include breakpoint(medium) {
    column-gap: 14px;
  }
  &:not(&.in-foot) {
    a.nav-item {
      width: 145px;
    }
    p.nav-item {
      width: 82px;
    }
  }
  
  .nav-item {
    transition: all .3s ease;
    cursor: pointer;
    color: $black-300;
    opacity: 0.82;
    &:active, &:focus {
      color: $green-100;  
    }
    &:hover {
      font-weight: 700;
    }
  }

  &.in-foot {
    flex-direction: column;
    gap: 18px;
    align-items: flex-start;
    .nav-item {
      color: $white;
      opacity: 1;
      &:hover {
        font-weight: 400;
        color: $green-300;
      }
      &:active, &:focus {
        color: rgba(217, 217, 217, 0.90);
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 700px) {
  .nav-content:not(.in-foot) {
    width: 100%;
    flex-direction: column;
    gap: 28px;
    padding: 0 20px;
    align-items: flex-start;
    .nav-item {
      width: 100%;
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;
      padding-bottom: 28px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.20);
      color: $white;
     

      &:hover, &:active, &:focus {
        color: $green-300;
      }
    }
  }
  .nav-content {
    .nav-item {
      width: 100% !important;
    }
  }

  .nav-content.in-foot {
    gap: 6px;
    .nav-item {
      width: max-content;
    }
  }
}

</style>
<template>
  <div class="relative parallax" :style="parentStyle" ref="parallaxRef">
    <!-- <div class="card target-element" :style="style">
      <div class="blank"></div>
    </div> -->
    <img class="target-element" :src="require('@/assets/img/'+img)" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
const props = defineProps({
  img: String,
  width: String | Number,
  height: String | Number,
  index: Number
});

const style = computed(() => ({
  backgroundImage: `url(${require('@/assets/img/' + props.img)})`
}))

const parentStyle = computed(() => ({
  width: props.width == "full" ? '100vw' : `${props.width}px`,
  minWidth: `${props.width}px`,
  height: `${props.height}px`
}))

const parallaxRef = ref(null);

const handleScroll = () => {
  const parallax = document.querySelectorAll('.target-element');
  let elementRect = parallax[props.index].getBoundingClientRect();
  let viewportHeight = window.innerHeight;
  if (elementRect.top < viewportHeight && elementRect.bottom > 0) {
    let elementHeight = parallax[props.index].clientHeight;
    let scrollPosition = elementRect.top;
    let movementFactor = elementHeight / 10000;
    parallax[props.index].style.top = `${-scrollPosition * movementFactor}px`;
  }


  // let scrollPosition = window.pageYOffset;
  // let elementHeight = parallax[props.index].clientHeight;
  // console.log(scrollPosition)
  // parallax[props.index].style.top = `-${scrollPosition * elementHeight / 1000 - 100}px`;
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
.parallax {
  position: relative;
  overflow: hidden;
  img {
    width: 111%;
    height: 109%;
    will-change: top, height, width;
    position: absolute;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
// .card {
//   background-image: var(--img);
//   background-size: 150% 140%;
//   background-position: center bottom 80%;
//   background-repeat: no-repeat;
//   // animation: parallax linear both;
//   // animation-timeline: view();
//   // animation-range: cover;
//   animation-duration: 1ms;
//   height: 100%;
//   width: 100%;
//   transform: translateZ(0);
//   // &.animate {
//   //   animation-name: parallax;
//   //   animation-duration: 1s;
//   //   animation-timing-function: linear;
//   //   animation-fill-mode: both;
//   // }
// }
// @keyframes parallax {
//   to {
//     background-position: center bottom 0%;
//   }
// }
// .card * {
//   mix-blend-mode: difference;
// }

// @media (max-width: 700px) {
//   .card {
//     animation-range: contain;
//     background-size: 105% 105%;
//     background-position: center bottom 90%;
//   }
// }
</style>
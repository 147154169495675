<template>
  <div class="home">
    <Header></Header>
    <main>
      <Banner></Banner>
      <PageBanner
        :image="windowWdt > 700 ? 'green.png' : 'green_mob.jpg'"
        :height="windowWdt > 700 ? 340 : 200"
      ></PageBanner>
      <About ref="about"></About>
      <Feature></Feature>
      <Services ref="services"></Services>
      <Partners ref="partner"></Partners>
      <PartnersParallax></PartnersParallax>
      <Last ref="values"></Last> 
    </main>
    <Footer></Footer>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import { useNavigationStore } from '@/store/navigation';

import Header from "@/components/common/Header.vue";
import Banner from "@/components/home/Banner.vue";
import PageBanner from "@/components/additional/PageBanner.vue";
import About from "@/components/home/About.vue";
import Feature from "@/components/home/Feature.vue";
import Services from "@/components/home/Services.vue";
import Partners from "@/components/home/Partners.vue";
import PartnersParallax from "@/components/additional/PartnersParallax.vue";
import Last from "@/components/home/Last.vue";
import Footer from "@/components/common/Footer.vue";
import getWindowWidth from '@/utils/getWindowWidth';

const windowWdt = getWindowWidth();
const navigationStore = useNavigationStore();
const about = ref(null);
const services = ref(null);
const partner = ref(null);
const values = ref(null);

watch(() => navigationStore.blockIndex, (newValue, oldValue) => {
  if (newValue !== 0 && navigationStore.blockClicked) {
    scrollToBlock(newValue)
  }
})

const getBlockRef = (index) => {
  switch(index) {
    case 'about':
      return about.value;
    case 'services':
      return services.value;
    case 'partner':
      return partner.value;
    case 'values':
      return partner.value;
  }
}

const scrollToBlock = (blockIndex) => {
  const blockRef = getBlockRef(blockIndex);
  if (blockRef.$refs[blockIndex]) {
    blockRef.$refs[blockIndex].scrollIntoView({ behavior: 'smooth' });
  }
  // setTimeout(() => {
  //   navigationStore.setBlockIndex(0);
  //   navigationStore.setBlockClicked(false);
  // }, 0)
}

onMounted(() => {
  if (navigationStore.blockIndex !== 0 && navigationStore.blockClicked) {
    setTimeout(() => {
      scrollToBlock(navigationStore.blockIndex)
    }, 0);
  }
})

onBeforeUnmount(() => {
  navigationStore.setBlockIndex(0);
  navigationStore.setBlockClicked(false);
})

</script>

<template>
  <header>
    <div
      class="head_content container flex items-center justify-between"
      v-motion :initial="{opacity: 0, y: -30}" :visibleOnce="{opacity: 1, y: 0, transition: { duration: 400 }}"  
    >
      <Logo></Logo>
      <div class="flex items-center justify-between">
        <Nav></Nav>
        <Button @click="isModal = true" isRainbow>Get in Touch</Button>
        <MenuButton @click="isMenu = true" :isClicked="isMenu" v-if="windowWdt <= 700"></MenuButton>
      </div>
    </div>
  </header>
  <Menu :isOpen="isMenu" @close="isMenu = false"></Menu>
  
  <Modal v-if="isModal" @close="isModal = false"></Modal>
</template>

<script setup>
import { ref, watch } from "vue";
import Logo from "@/components/common/Logo.vue";
import Button from "@/components/common/Button";
import Nav from "@/components/common/Nav";
import Modal from "@/components/ui/Modal.vue";
import MenuButton from "@/components/mobile/MenuButton.vue";
import Menu from "@/components/mobile/Menu.vue";

import getWindowWidth from '@/utils/getWindowWidth';

const windowWdt = getWindowWidth();

const isModal = ref(false);
const isMenu = ref(false);

watch(() => isMenu.value, () => {
  if(isMenu.value) document.body.classList.add('no-scroll');
  else document.body.classList.remove('no-scroll');
})
</script>

<style lang="scss" scoped>
header {
  padding: 24px 0;
  .head_content {
    width: 100%;
    
    & > .flex {
      gap: 60px;
      @include breakpoint(large) {
        gap: 20px;
      }
    }
  }
}

@media (max-width: 700px) {
  header {
    nav {
      display: none;
    }
  }
}
</style>
<template>
  <div class="container banner flex flex-col relative">
    <ImageBlock
      animate
      v-motion
      :initial="{opacity: 0}" 
      :visibleOnce="{opacity: 1, transition: { duration: 1300 }}"
      img="shape1.png"
      fitType="cover"
      :width="windowWdt > 1200 ? '618px' : windowWdt < 700 ? '320px' : '450px'"
      :height="windowWdt > 1200 ? '618px' : null"
      :margin="windowWdt > 1200 ? '-73px -60px 0 auto' : windowWdt < 700 ? '-40px -45px 0 auto' : '-60px -100px 0 auto'"
    ></ImageBlock>
    
    <div class="content flex items-end justify-between gap18">
      <p
        class="title1"
        v-motion
        :initial="{opacity: 0, y: -60}" :visibleOnce="{opacity: 1, y: 0, transition: { duration: 900 }}"
      >We take care of your success as our own</p>
      <div class="relative">
        <Ellipse size="40"></Ellipse>
        <p
          class="headline1 z2"
          v-motion
          :initial="{opacity: 0, y: -30}" :visibleOnce="{opacity: 1, y: 0, transition: { duration: 800 }}"
        >Rillius is standing by your business and offering ongoing support, guidance, and partnership as you navigate the ever-evolving landscape of success</p>
      </div>
    </div>

    <ScrollDown></ScrollDown>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import Ellipse from '../additional/Ellipse.vue';
import ImageBlock from '../additional/ImageBlock.vue';
import ScrollDown from "@/components/additional/ScrollDown.vue";
import getWindowWidth from '@/utils/getWindowWidth';

const windowWdt = getWindowWidth();

</script>

<style lang="scss" scoped>
.banner {
  padding-bottom: 60px;
  & > .content {
    margin-top: -130px;
  }
}

.headline1 {
  @include set-width(410px);
  margin-bottom: 10px;
}

.title1 {
  @include set-width(745px);
  text-transform: uppercase;
}

@media (max-width: 1200px) {
  .banner {
    & > .content {
      margin-top: 20px;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>

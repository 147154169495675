<template>
  <section class="h-feature relative">
    <svg class="svg" xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 0 1440 40" fill="none">
      <path id="animated-path" d="M0 39L78.7431 14.823C108.202 5.778 139.566 4.91063 169.48 12.3136L234.776 28.4731C262.678 35.3782 291.875 35.0959 319.638 27.6526L374.116 13.0471C403.532 5.16068 434.526 5.32112 463.858 13.5116L506.623 25.4529C538.287 34.2943 571.835 33.7652 603.204 23.9297L625.366 16.9809C658.546 6.57768 694.118 6.59928 727.285 17.0427L747.876 23.5264C779.918 33.6156 814.23 33.9857 846.482 24.59L877.993 15.41C910.245 6.01434 944.558 6.38442 976.599 16.4735L999.636 23.7274C1031.28 33.691 1065.15 34.1786 1097.06 25.1299L1136.24 14.0224C1166.28 5.50556 1198.09 5.427 1228.17 13.7954L1270.55 25.5837C1302.03 34.342 1335.37 33.84 1366.58 24.1377L1441 1" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="1 3"/>
    </svg>
    <svg class="svg" xmlns="http://www.w3.org/2000/svg" height="46" viewBox="0 0 1440 46" fill="none">
      <path id="animated-path2" d="M1456 1L1381.69 24.118C1350.47 33.8328 1317.11 34.3355 1285.6 25.5658L1243.38 13.8125C1213.28 5.43314 1181.45 5.5118 1151.39 14.0398L1112.37 25.1113C1080.43 34.1718 1046.54 33.6837 1014.88 23.7071L991.987 16.4941C959.926 6.39187 925.59 6.02129 893.318 15.4292L861.96 24.5708C829.689 33.9787 795.353 33.6081 763.292 23.5059L742.848 17.064C709.661 6.60704 674.064 6.58543 640.865 17.0021L618.849 23.9097C587.461 33.758 553.89 34.2878 522.207 25.4348L479.596 13.5284C450.246 5.32711 419.23 5.16645 389.796 13.0632L335.473 27.6374C307.693 35.0905 278.475 35.3732 250.556 28.4589L185.423 12.3289C155.491 4.91608 124.106 5.78456 94.6287 14.8413L16 39L-3 45.5" stroke="#A6A6A6" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="1 3"/>
    </svg>
    <div class="container">
      <div class="relative">
        <Ellipse size="40"></Ellipse>
        <p
          class="headline7 relative z2"
          v-motion
          :initial="{opacity: 0, y: -10}" 
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 600, delay: 300 }}"
        >Our feature</p>
      </div>

      <BlockSlider
        blockName="feature"
        :slides="slides"
      ></BlockSlider>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import BlockSlider from "@/components/slider/BlockSlider.vue";
import Ellipse from "../additional/Ellipse.vue";

const slides = ref([
  {
    img: "feature1.jpg",
    title: "Business focus",
    text: "We offer solutions focused on business and profit maximization.",
  },
  {
    img: "feature2.jpg",
    title: "Partnership",
    text: "We build long-term partnerships with clients.",
  },
  {
    img: "feature3.jpg",
    title: "Meaningfulness",
    text: "It is important for us not just to provide a service, but to determine the goals of the client and offer the best solution.",
  }
])

</script>

<style lang="scss" scoped>
.svg {
  position: absolute;
  width: 100vw;
  height: auto;
  &:nth-child(1) {
    top: 170px;
    left: 0;
  }
  &:nth-child(2) {
    top: 180px;
    left: 0;
  }
}
@keyframes dash {
  to {
    stroke-dashoffset: -100;
  }
}

@keyframes dash2 {
  to {
    stroke-dashoffset: -150;
  }
}
#animated-path {
  stroke: black;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 1 5;
  animation: dash 10s linear infinite;
}
#animated-path2 {
  stroke: #A6A6A6;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 1 5;
  animation: dash2 9s linear infinite;
}
.h-feature {
  margin: 0 0 100px;
  overflow: hidden;
  .container {
    overflow: visible;
  }
  .container > .relative {
    margin-top: 125px;
    @include breakpoint(medium) {
      margin-top: 40px;
    }
    @include breakpoint(small) {
      margin-top: 15px;
    }
  }
  .headline7 {
    margin-bottom: 32px;
  }
}
</style>
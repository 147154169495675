<template>
  <section ref="values" class="h-last">
    <div class="container flex items-center justify-between">
      <div
        class="flex flex-col h-last__box"
        v-motion
        :initial="{opacity: 0, y: -50}" 
        :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 400 }}"
      >
        <p class="text3">We</p>
        <p class="subtitle2">Maximize the value</p>
        <p class="text4">Of our assets through the creation of sponsored content, partnerships and targeted advertising campaigns. By leveraging our expertise in content creation and distribution, we ensure that every piece of sponsored content resonates with our target audience, effectively conveying our clients' messaging and value propositions.
          <br/><br/>
          The company has an impressive history in sports sponsorship, serving as the official sponsor for the Football Club for the 22/23 and 23/24 seasons.
        </p>
      </div>

      <ImageBlock
        v-motion
        :initial="{opacity: 0}" 
        :visibleOnce="{opacity: 1, transition: { duration: 700, delay: 400 }}"
        img="shape4.png"
        :margin="windowWdt < 900 ? '0 -30px 0 0' : '0 32px 0 0'"
      ></ImageBlock>
    </div>
  </section>
</template>

<script setup>
import ImageBlock from "@/components/additional/ImageBlock.vue";
import getWindowWidth from '@/utils/getWindowWidth';

const windowWdt = getWindowWidth();
</script>

<style lang="scss" scoped>
.h-last {
  padding: 100px 0;
  &__box {
    @include set-width(502px);
    p {
      &.text3 {
        color: $black-300;
        opacity: 0.4;
      }
      &.text4 {
        margin-top: 18px;
      }
    }
  }
}

@media (max-width: 700px) {
  .h-last {
    padding: 40px 0 60px;
    & > .container.flex {
      flex-direction: column;
      align-items: flex-start;
      gap: 50px;
    }
    &__box {
      max-width: 100%;
    }
    .img-wrap {
      width: calc(100% - 40px);
      max-width: 500px;
      margin: 0 auto !important;
    }
  }
}
</style>
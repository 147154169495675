<template>
  <div class="explore flex justify-between relative" @click="isModal = true">
    <div class="line absolute"></div>
    <p class="subtitle3 white">Explore <br/>our success</p>
    <div class="explore__right flex justify-center items-center relative">
      <div class="line absolute"></div>
      <div class="line absolute"></div>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M0.868281 15.7174C0.477756 16.1079 0.477756 16.7411 0.868281 17.1316C1.2588 17.5221 1.89197 17.5221 2.28249 17.1316L0.868281 15.7174ZM16.4246 1.57526L17.4246 1.57526C17.4246 1.31005 17.3193 1.05569 17.1317 0.868156C16.9442 0.68062 16.6898 0.575263 16.4246 0.575263L16.4246 1.57526ZM3.69671 0.575265C3.14442 0.575265 2.69671 1.02298 2.69671 1.57526C2.69671 2.12755 3.14442 2.57526 3.69671 2.57526L3.69671 0.575265ZM15.4246 14.3032C15.4246 14.8555 15.8723 15.3032 16.4246 15.3032C16.9769 15.3032 17.4246 14.8555 17.4246 14.3032L15.4246 14.3032ZM2.28249 17.1316L17.1317 2.28237L15.7175 0.868156L0.868281 15.7174L2.28249 17.1316ZM3.69671 2.57526L16.4246 2.57526L16.4246 0.575263L3.69671 0.575265L3.69671 2.57526ZM17.4246 14.3032L17.4246 1.57526H15.4246L15.4246 14.3032L17.4246 14.3032Z" fill="white"/>
      </svg>
    </div>
  </div>
  <Modal v-if="isModal" @close="isModal = false"></Modal>
</template>

<script setup>
import { ref } from "vue";
import Modal from "@/components/ui/Modal.vue";

const isModal = ref(false);
</script>

<style lang="scss" scoped>
.line {
  width: 1px;
  height: 24px;
  background: $grey-300;
  z-index: 2;
  mix-blend-mode: multiply;
}

.explore {
  width: 100%;
  border-radius: 16px;
  background: url('@/assets/img/footer-green.png');
  @include breakpoint(small) {
    background: url('@/assets/img/footer-green-mob.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  cursor: pointer;

  & > .line {
    transform: rotate(90deg) translateY(-49%) translateX(-12px);
    top: 50%;
  }
  & > p {
    padding: 52px 64px;
    transition: all .3s ease;
  }
  & > svg {
    transition: all .3s ease;
  }
  &__right {
    width: 15%;
    max-width: 180px;
    .line {
      &:nth-child(1) {
        top: 0;
        left: 0;
      }
      &:nth-child(2) {
        bottom: 0;
        left: 0;
      }
    }
  }
}

@media (min-width: 700px) {
  .explore{
    &:hover {
      p {
        color: $green-300;
      }
      svg path {
        fill: $green-300;
      }
    }
    &:active, &:focus {
      p {
        color: $white;
      }
      svg path {
        fill: $white;
      }
    }
  }
}

@media (max-width: 700px) {
  .explore {
    & > p {
      padding: 54px 54px 54px 26px;
    }
    & > .line {
      height: 14px;
    }
    &__right{
      width: 50%;
      max-width: none;
    }
  }
}
</style>
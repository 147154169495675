import { defineStore } from 'pinia'

export const useNavigationStore = defineStore({
  id: 'navigation',
  state: () => ({
    blockIndex: 0,
    blockClicked: false
  }),
  actions: {
    setBlockIndex(index) {
      this.blockIndex = index
    },
    setBlockClicked(value) {
      this.blockClicked = value
    }
  }
})


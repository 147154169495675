<template>
  <div class="response-modal flex items-center justify-center">
    <div class="response-modal__content relative" :style="style">
      <svg class="absolute" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M6 18L18 6M6 6L18 18" stroke="white" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

      <div class="block flex flex-col items-center">
        <p class="headline7 white">{{ isSuccess ? 'Success' : 'Error' }}</p>
        <div class="flex flex-col items-center">
          <img :src="require(`@/assets/img/${isSuccess ? 'success.png' : 'error.png'}`)" alt="rillius response modal icon" />
          <p v-if="isSuccess" class="white text4">Your application has been accepted. <br/>You will be contacted shortly for further details.</p>
          <p v-else class="white text4">Something went wrong. <br/>Please try again!</p>
        </div>
        <div class="flex buttons">
          <template v-if="isSuccess">
            <Button class="green" withBorder @click="getContinueOrHide('hide')">Continue</Button>
          </template>
          <template v-else>
            <Button withBorder @click="getContinueOrHide('hide')">Close</Button>
            <Button withBorder @click="getContinueOrHide">Try again</Button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import Button from "@/components/common/Button.vue";

const emit = defineEmits(['close']);

const props = defineProps({
  isSuccess: Boolean
})

const style = computed(() => ({
  border: props.isSuccess ? "1px solid rgba(217, 217, 217, 0.10" : "1px solid rgba(217, 217, 217, 0.10)",
  background: props.isSuccess ? "linear-gradient(180deg, rgba(36, 255, 0, 0.15) -135.1%, rgba(0, 0, 0, 0.20) 69.27%), #000" : "linear-gradient(180deg, rgba(154, 94, 253, 0.20) -18.44%, rgba(0, 0, 0, 0.20) 47.81%), #000"
}))

const getContinueOrHide = (val) => {
  if(val === 'hide') {
    emit('close', 'hide');
  } else {
    emit('close', 'cont');
  }
}
</script>

<style lang="scss" scoped>
.response-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.60);
  backdrop-filter: blur(18px);
  
  &__content {
    padding: 40px;
    border-radius: 33px;
    @include set-width(500px);
    svg {
      right: 24px;
      top: 24px;
    }
    img {
      width: 200px;
      height: 200px;
    }
    
    .block {
      gap: 18px;

      & > .flex-col {
        gap: 8px;
        text-align: center;
        @include set-width(360px);
      }

      .buttons, button {
        gap: 8px;
        width: 100%;
        &:nth-child(2) {
          color: $purple-200;
          &:hover {
            color: $purple-100;
          }
          &:active, &:focus {
            color: $purple-300;
          }
        }
        &:nth-child(1):not(&.green) {
          &:hover {
            color: $purple-200;
          }
          &:active, &:focus {
            color: $purple-300;
          }
        }
        &:nth-child(1).green {
          &:hover {
            color: $green-300;
          }
          &:active, &:focus {
            color: $green-700;
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .response-modal {
    &__content {
      padding: 32px;
      width: calc(100% - 40px);
      .block {
        .buttons {
          flex-direction: column-reverse;
        }
      }
    }
  }
}
</style>
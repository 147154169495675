<template>
  <swiper
    ref="swiperRef"
    :slides-per-view="blockName==='feature' ? windowWdt < 700 ? 1 : 'auto' : 1"
    :space-between="80"
    @swiper="onSwiper"
    :modules="[Navigation]"
    :class="{'visible': blockName === 'feature'}"
  >
    <swiper-slide v-for="(slide, index) in slides" :key="index">
      <FeatureBlockItem
        v-if="blockName === 'feature'"
        :index="index+1"
        :img="slide?.img"
        :title="slide?.title"
        :text="slide?.text"
        v-motion
        :initial="{opacity: 0}" 
        :visibleOnce="{opacity: 1, transition: { duration: 1000, delay: (index+1)*200 }}"
      ></FeatureBlockItem>
      <ServiceItem
        v-if="blockName === 'service'"
        :key="slide.title"
        :icon="slide.icon"
        :title="slide.title"
        :text="slide.text"
      ></ServiceItem>
      <MValuesItem
        v-if="blockName === 'values'"
        :key="slide.title+idx"
        :title="slide?.title"
        :text="slide?.text"
        :image="slide?.image"
        :index="index"
      ></MValuesItem>
    </swiper-slide>
    <div class="controls flex items-center" :class="{'justify-end': blockName !== 'feature'}">
      <SliderControl
        @click="goPrev"
        :disabled="swiperInstance?.activeIndex === 0"
        :isWhite="blockName === 'service'"
      ></SliderControl>
      <SliderControl
        @click="goNext"
        btnType="next"
        :disabled="swiperInstance?.activeIndex === slides.length-1"
        :isWhite="blockName === 'service'"
      ></SliderControl>
    </div>
  </swiper>
</template>

<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

import SliderControl from "./SliderControl.vue";
import FeatureBlockItem from "@/components/additional/FeatureBlockItem.vue";
import ServiceItem from "../additional/ServiceItem.vue";
import MValuesItem from "../additional/MValuesItem.vue";
import getWindowWidth from '@/utils/getWindowWidth';

const windowWdt = getWindowWidth();

const props = defineProps({
  slides: Array,
  blockName: String
})

const swiperInstance = ref(null);
const slideNum = ref(1);
const onSwiper = (swiper) => {
  swiperInstance.value = swiper;
};

const goNext = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slideNext();
  }
};

const goPrev = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slidePrev();
  }
};

</script>

<style lang="scss" scoped>
.swiper {
  overflow: clip;
  &.visible {
    overflow: visible;
  }
  .swiper-slide {
    width: auto !important;
    @include breakpoint(medium) {
      width: 100% !important;
    }
  }

  .controls {
    gap: 20px;
    margin-top: 32px;
  }
}
</style>

export const NAVS = [
  {
    label: "About Us",
    href: "",
    ref: "about"
  },
  {
    label: "Mission & Vision",
    href: "/mission-vision"
  },
  {
    label: "Services",
    href: "",
    ref: "services"
  },
  {
    label: "Partner",
    href: "",
    ref: "partner"
  },
]
<template>
  <div
    class="img-wrap"
    :class="{fitType, 'animate': animate}"
    :style="style"
  >
    <img :src="require(`@/assets/img/${img}`)" alt="rillius picture" />
  </div>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  img: String,
  fitType: String,
  fontSize: String | Number,
  width: String | Number,
  height: String | Number,
  margin: String,
  animate: Boolean
})

const style = computed(() => ({
  width: props.width === 'full' ? '100vw' : `${props.width}`,
  minWidth: props.width === 'full' ? '100vw' : `${props.width}`,
  height: !!props.height ? `${props.height}` : 'auto',
  minHeight: !!props.height ? `${props.height}` : 'auto',
  margin: props.margin
}))
</script>

<style lang="scss" scoped>
.img-wrap {
  &.animate {
    img {
      animation: rotate 10s linear infinite;
    }
  }
  img {
    @include block-img(contain);
  }
  &.cover img {
    @include block-img(cover);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
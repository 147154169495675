<template>
  <svg class="element" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
    <g filter="url(#filter0_f_362_4524)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M48.227 14.9714V4C43.6179 4 39.969 5.28323 36.8889 7.81413C32.4364 11.4729 30.3984 17.3208 30.3984 25.1958V30.4V52H51.9984V30.4H41.3168V25.1958C41.317 15.1793 45.7249 14.9714 48.227 14.9714Z" fill="#D3FABD"/>
    </g>
    <g filter="url(#filter1_f_362_4524)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.8286 14.9714V4C17.2195 4 13.5705 5.28323 10.4905 7.81413C6.03795 11.4729 4 17.3208 4 25.1958V30.4V52H25.6V30.4H14.9184V25.1958C14.9186 15.1793 19.3264 14.9714 21.8286 14.9714Z" fill="#D3FABD"/>
    </g>
    <defs>
      <filter id="filter0_f_362_4524" x="26.3984" y="0" width="29.5996" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_362_4524"/>
      </filter>
      <filter id="filter1_f_362_4524" x="0" y="0" width="29.5996" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_362_4524"/>
      </filter>
    </defs>
  </svg>
</template>

<style lang="scss" scoped>
.element {
  position: absolute;
  top: -20px;
  left: -25px;
}
</style>
<template>
  <div
    id="scrollToTopBtn"
    class="go-top flex justify-center items-center"
    @click="scrollToTop"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <path d="M24.5 19.25L14 8.75L3.5 19.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const scrollToTopBtn = ref(null);

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

const isShow = ref(false);

const handleScroll = () => {
  if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
    isShow.value = true;
  } else {
    isShow.value = false;
  }
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};

</script>

<style lang="scss" scoped>
.go-top {
  font-size: 64px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 1px solid $white;
  transition: all .3s ease;
  cursor: pointer;
  svg path {
    transition: stroke .3s ease;
  }
  &:hover {
    border: 1px solid rgba(184, 255, 191, 0.20);
    background: $black-200;
    svg path {
      stroke: $green-300;
    }
  }
  &:active, &:focus {
    svg path {
      stroke: $white;
    }
  }
}
</style>
<template>
  <Transition name="menu">
    <div v-if="isOpen" class="menu mob-menu">
      <div class="mob-menu__bg absolute"></div>
      <main class="relative flex flex-col">
        <div class="mob-menu__head flex items-center justify-between">
          <Logo place="menu" width="55px"></Logo>
          <div class="close-btn" @click="isCrossClicked = true, $emit('close')">
            <Icon :isHovered="isCrossClicked" iconName="cross"></Icon>
          </div>
        </div>
        <div class="mob-menu__body flex items-center">
          <Nav @toggle="isCrossClicked = true, $emit('close')"></Nav>
        </div>
        <div class="mob-menu__foot flex flex-col">
          <div class="flex items-center justify-between">
            <a class="small3" href="https://www.linkedin.com/company/rillius/" target="_blank">Linkedin</a>
            <a class="small3">info@rillius.com</a>
          </div>
          <div class="flex items-center justify-between">
            <RouterLink class="small3" to="/privacy">Privacy Policy</RouterLink>
            <p class="small3 copyright">© 2024 — by Rillius Holding Ltd</p>
          </div>
        </div>
      </main>
    </div>
  </Transition>
</template>

<script setup>
import { ref, watch } from "vue";
import Logo from "@/components/common/Logo.vue";
import Icon from "@/components/ui/Icon.vue";
import Nav from "@/components/common/Nav.vue";

const emit = defineEmits(['close']);
const props = defineProps({
  isOpen: Boolean
});

const isCrossClicked = ref(false);

watch(() => props.isOpen, () => {
  if(!props.isOpen) {
    isCrossClicked.value = false;
  }
})
</script>

<style lang="scss">
.mob-menu {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  
  &__bg {
    width: 200%;
    height: 100%;
    background-image: url("@/assets/img/modal-bg.jpg");
    background-size: cover;
    background-position: 100% 50%;
    background-repeat: no-repeat;
    z-index: -1;
  }

  main {
    z-index: 999;
    height: 100%;
  }

  &__head {
    height: 70px;
    min-height: 70px;
    padding: 0 20px;
  }

  &__body {
    height: 100%;
    padding: 30px 0 20px;
    overflow-y: scroll;
  }

  &__foot {
    padding: 40px 20px;
    gap: 16px;

    .small3 {
      opacity: 0.5;
      text-decoration: none;
      transition: 0.3s ease;
      &:not(&.copyright){
        cursor: pointer;
        &:hover, &:active, &:focus {
          color: $green-300;
        }
      } 
    }
  }
}

.menu-enter-active,
.menu-leave-active {
  transition: transform 0.5s ease;
}

.menu-enter-from,
.menu-leave-to {
  transform: translateX(100%);
}

.menu-enter-to,
.menu-leave-from {
  transform: translateX(0);
}
</style>
<template>
  <div
    class="service-item flex items-center justify-between"
    @mouseenter="() => {if(windowWdt > 700) isHovered = true}"
    @mouseleave="isHovered = false"
    v-motion
    :initial="{opacity: 0, x: 50}" 
    :visibleOnce="{opacity: 1, x: 0, transition: { duration: 600 }}"
  >
    <div
      class="left flex flex-col"
      v-motion
      :initial="{opacity: 0}" 
      :visibleOnce="{opacity: 1, transition: { duration: 700, delay: 400 }}"
    >
      <div class="left__icon flex items-center justify-center">
        <Icon :iconName="icon" :isHovered="isHovered"></Icon>
      </div>
      <p class="subtitle2">{{ title ?? "" }}</p>
    </div>

    <div class="right">
      <p
        class="text4"
        v-motion
        :initial="{opacity: 0, y: -50}" 
        :visibleOnce="{opacity: 1, y: 0, transition: { duration: 700, delay: 400 }}"
      >{{ text ?? "" }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Icon from "@/components/ui/Icon.vue";
import getWindowWidth from '@/utils/getWindowWidth';

const windowWdt = getWindowWidth();

const props = defineProps({
  icon: String,
  title: String,
  text: String
})

const isHovered = ref(false);
</script>

<style lang="scss" scoped>
.service-item {
  border-radius: 24px;
  border: 1px solid $grey-100;
  background: $black;
  padding: 90px 90px 90px 60px;
  transition: all .3s ease;
  cursor: pointer;
  gap: 20px;

  .left {
    gap: 6px;
    width: 315px;
    &__icon {
      font-size: 50px;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background: rgba(217, 217, 217, 0.10);
    }
  }

  .subtitle2 {
    color: rgba(217, 217, 217, 0.94);
    opacity: 0.92;
    transition: all .3s ease;
    text-align: left;
  }

  .text4 {
    color: rgba(217, 217, 217, 0.90);
    @include set-width(640px);
    transition: all .3s ease;
  }

  .right {
    p {
      text-align: left;
    }
  }
}

@media (min-width: 701px) {
  .service-item {
    &:hover {
      border: 1px solid rgba(184, 255, 191, 0.20);
      background: $black-200;
      box-shadow: 0px 0px 31px 8px rgba(184, 255, 191, 0.10) inset;

      .subtitle2, .text4 {
        color: $white;
        opacity: 1;
      }
    }
  }
}

@media (max-width: 900px) {
  .service-item {
    padding: 26px;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .left {
      gap: 8px;
    }
    p {
      text-align: left;
    }
  }
}
</style>
<template>
  <section ref="services" class="h-services">
    <div class="container">
      <div class="relative">
        <Ellipse size="40"></Ellipse>
        <p
          class="headline7"
          v-motion
          :initial="{opacity: 0, y: -10}" 
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 800, delay: 300 }}"
        >Services</p>
      </div>
      <p
        class="text4"
        v-motion
        :initial="{opacity: 0, y: -20}" 
        :visibleOnce="{opacity: 1, y: 0, transition: { duration: 800, delay: 300 }}"
      >We are confident that you will be satisfied with our work</p>

      <div v-if="windowWdt > 900" class="h-services__content flex flex-col">
        <ServiceItem
          v-for="(service, idx) in items"
          :key="service.title"
          :icon="service.icon"
          :title="service.title"
          :text="service.text"
        ></ServiceItem>
      </div>

      <BlockSlider
        v-else
        blockName="service"
        :slides="items"
      ></BlockSlider>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import Ellipse from "@/components/additional/Ellipse.vue";
import ServiceItem from "@/components/additional/ServiceItem.vue";
import BlockSlider from "../slider/BlockSlider.vue";
import getWindowWidth from '@/utils/getWindowWidth';

const windowWdt = getWindowWidth();

const items = ref([
  {
    icon: "eye",
    title: "Elevate online visibility",
    text: "Our comprehensive SEO services are designed to elevate your online presence and drive targeted traffic to your website. Through a meticulous analysis of your industry, competitors, and target market, we develop customized SEO plans tailored to your unique needs and goals."
  },
  {
    icon: "star",
    title: "Building strong brand identities",
    text: "Our approach to building strong brand identities begins with a deep understanding of your business, values, and target audience. We work closely with you to uncover what makes your brand unique and identify the key attributes that resonate with your customers. "
  },
  {
    icon: "announce",
    title: "Amplify advertising impact",
    text: "Central to our advertising approach is the use of data-driven insights to inform decision-making and optimize campaign performance. By leveraging advanced analytics and targeting capabilities, we ensure that your ads are reaching the right audience, at the right time, and in the right place. "
  },
  {
    icon: "people",
    title: "Engage and expand social reach",
    text: "With our expert guidance and proven strategies, you can unlock the full potential of social media and achieve your business objectives with confidence. From eye-catching visuals to engaging video content and thought-provoking copy, we tailor our content to align with your brand identity and messaging goals. "
  }
])
</script>

<style lang="scss">
.h-services {
  background: $black;
  padding-bottom: 98px;
  .container > .relative {
    margin: 100px auto 0;
    text-align: center;
    width: min-content;
  }
  p {
    color: $white;
    text-align: center;
    &.text4 {
      margin-top: 18px;
    }
  }
  &__content {
    margin-top: 60px;
    gap: 22px;
  }
}

@media (max-width: 900px) {
  .h-services {
    padding-bottom: 60px;
    & > .container > .relative {
      margin: 40px auto 0;
    }
    .swiper .swiper-slide {
      height: 440px;
      width: 100% !important;
      margin-right: 80px !important;
    }
    p {
      &.text4 {
        margin: 20px 0 32px;
      }
    }
  }
}
</style>